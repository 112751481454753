import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreLoginHeaderComponent } from './pre-login-header.component';

@NgModule({
    declarations: [
        PreLoginHeaderComponent
    ],
    exports : [
        PreLoginHeaderComponent
    ],    
    imports: [
        CommonModule        
    ]
})
export class PreLoginHeaderModule { }
