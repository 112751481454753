import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, of, throwError } from 'rxjs';
import { AppConfigService } from 'src/app/appServiceConfig';
import { environment } from 'src/environments/environment';
import { facilityListAPIResponse, facilityListModel } from '../models/facilitylist.model';
import { LiveManualResponse, Section } from '../models/manual.model';
import { MANUAL_TYPES } from '../mydiet-constants';


@Injectable({
  providedIn: 'root'
})

export class MyDietCommonService {

  public _oAuthToken: string | null = null;
  public _oAuthTokenValue: string = "";

  public facilityChange$: BehaviorSubject<facilityListModel> = new BehaviorSubject<any>(null);
  facilityChange = this.facilityChange$.asObservable();
  selfServiceDisable: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
  }

  disableSelfService() {
    this.selfServiceDisable.next(true);
  }

  getHeadersFileIO(): HttpHeaders {
    const authToken = this.oAuthTokenValue;
    let headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);
    return headers;
  }

  getHeadersWithoutToken(): HttpHeaders {
    const authToken = this.oAuthTokenValue;
    let headers = new HttpHeaders().
      set('Content-Type', 'application/json',).set('Authorization', `Bearer ${authToken}`).
      set('Access-Control-Allow-Origin', '**');
    return headers;
  }

  getHeaders(): HttpHeaders {
    const authToken = this.oAuthToken;
    let headers = new HttpHeaders().set('Content-Type', 'application/json',).set('Authorization', `Bearer ${authToken}`).set('Access-Control-Allow-Origin', '**');
    return headers;
  }

  get oAuthToken(): string | null {
    return this._oAuthToken;
  }

  set oAuthToken(value: string | null) {
    if (value) {
      this._oAuthToken = value;
    } else {
      this._oAuthToken = null;
    }
  }

  get oAuthTokenValue(): string {
    return this._oAuthTokenValue;
  }

  set oAuthTokenValue(value: string) {
    if (value) {
      this._oAuthTokenValue = value;
    } else {
      this._oAuthTokenValue = "";
    }
  }

  replaceParamsForUrl(url: string, params: any) {
    let customUrl;
    let tempUrl = url;
    for (let valueParams of params) {
      if (tempUrl.indexOf(valueParams.key) > -1) {
        let value = "{" + valueParams.key + "}";
        tempUrl = tempUrl.replace(value, valueParams.value);
        customUrl = tempUrl;
      } else {
        customUrl = url;
      }
    }
    return customUrl;
  }

  submitSignUp(body: any): Observable<any> {
    const headers = this.getHeadersWithoutToken();
    return this.http.post(this.appConfig.getConfig().api.uri + environment.urls.userRegistration, body, { headers });
  }

  getAllApprovals(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(this.appConfig.getConfig().api.uri + environment.urls.getAllApproval, { headers });
  }

  deleteApprovalRecord(obj: any): Observable<any> {
    const headers = this.getHeaders();
    return this.http.put(this.appConfig.getConfig().api.uri + environment.urls.rejectApprovalData, obj, { headers });
  }

  approveUserRecord(body: any): Observable<any> {
    const headers = this.getHeaders();
    return this.http.post(this.appConfig.getConfig().api.uri + environment.urls.approveUserData, body, { headers });
  }

  getRbacProfileCustom(): Observable<any> {
    const headers = this.getHeaders();
    // return of(rbac_sys);
    return this.http.get(this.appConfig.getConfig().api.uri + environment.urls.rbacProfileMyDiet, { headers });
  }

  getFacilitiesList(username: any): Observable<facilityListAPIResponse | any> {
    // return of(cost_center_list)
    const headers = this.getHeaders();
    let queryParam1 = "userName";
    let params = [];
    params.push({ key: queryParam1, value: username });
    const url = this.replaceParamsForUrl(environment.urls.getFacilitiesList, params);
    return this.http.get<facilityListAPIResponse>(this.appConfig.getConfig().api.uri + url, { headers });
  }

  validateOrder(value: any) {
    let errMsg;
    if (!(/^\d*\.?\d*$/.test(value))) {
      errMsg = 'Order should be numeric.'
    }
    else if (value && !Number.isInteger(Number(value))) {
      errMsg = 'Order should be whole number and less than 30000.'
    }
    else if (value.includes('.') && value % 1 === 0) {
      errMsg = 'Order should be a whole number and less than 30000.';
    }
    else if (value && Number(value) <= 0) {
      errMsg = 'Order should be more than 0.'

    }
    else if (value && Number(value) > 30000) {
      errMsg = 'Order should be less than 30000.'
    }
    else {
      errMsg = null;
    }
    return errMsg;
  }

  transformSearchResultsToSections(data: any[]): Section[] {
    const sectionMap: { [key: number]: Section } = {};

    data.forEach(item => {
      if (!sectionMap[item.id]) {
        sectionMap[item.id] = {
          id: item.id,
          manualId: item.manualId,
          sectionName: item.sectionName,
          fileName: item.fileName,
          order: 0,
          manualToc: []
        };
      }

      sectionMap[item.id].manualToc.push({
        "id": item.tocId,
        "name": item.tocName.trim(),
        orderId: item.tocOrder,
        parentId: item.tocParentId,
        subMenu: []
      });

    });

    const sections = Object.values(sectionMap).sort((a, b) => a.order - b.order);

    // Sort manualToc inside each section by tocOrder
    sections.forEach(section => {
      section.manualToc.sort((a, b) => a.orderId - b.orderId);
    });

    return sections;
    //return Object.values(sectionMap);
  }

  getBadgeCount(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(this.appConfig.getConfig().api.uri + environment.urls.getBadgeCount, { headers })
      .pipe(
        map((response: any) => {
          if (response && response.data) {
            return response
          } else {
            return null;
          }
        }),
        catchError((err: any) => {
          console.error("Error" + err);
          return of([]);
        }

        )
      );
  }

  getAllPatientEducationLanguages(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(this.appConfig.getConfig().api.uri + environment.urls.getAllPatientEducationLanguages, { headers });
  }

  getAllPatientEducationDocumentGroups(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(this.appConfig.getConfig().api.uri + environment.urls.getAllPatientEducationDocumentGroups, { headers });
  }

  getPatientEducationDocuments(documentGroupId: Number) {
    const headers = this.getHeaders();
    let url = environment.urls.crudPatientEducationDocument + '?documentGroupId=' + documentGroupId;
    return this.http.get<any>(this.appConfig.getConfig().api.uri + url, { headers });
  }

  getActiveManual(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get<any>(this.appConfig.getConfig().api.uri + environment.urls.getActiveManual, { headers: headers });
  }

  getLiveManualToC(manualId: Number): Observable<any> {
    const headers = this.getHeaders();
    let params = [];
    params.push({ key: "manualId", value: manualId });
    const url = this.replaceParamsForUrl(environment.urls.getManualMenus, params);
    return this.http.get<any>(this.appConfig.getConfig().api.uri + url, { headers: headers });
  }

  getSelectedToC(manualId: string, tocId: string): Observable<any> {
    const headers = this.getHeadersFileIO();
    let params = [];
    params.push({ key: "manualTocId", value: tocId });
    params.push({ key: "manualId", value: manualId });
    const url = this.replaceParamsForUrl(environment.urls.getManualToc, params);
    return this.http.get(this.appConfig.getConfig().api.uri + url, { headers: headers, responseType: 'blob' });
  }

  getManualSearchResults(manualId: Number, searchText: string): Observable<any> {
    const headers = this.getHeaders();
    let url = environment.urls.getManualDocumentSearchToC + '/' + manualId + '/' + searchText;
    return this.http.get(this.appConfig.getConfig().api.uri + url, { headers });
  }

  getManualType(manualType: Number) {
    return MANUAL_TYPES.find(x => x.id == manualType)?.manualType;
  }

}