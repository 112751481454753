import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss']
})
export class CustomDropdownComponent implements ICellRendererAngularComp {
  params: any;
  masterData: string[] = [];
  selectedValues: string;
  requiredField: boolean = false;
  filteredValues: Observable<string[]>;
  ddControl = new FormControl();
  options: string[] = [];
  disablePointerEvent: boolean = false;
  lastFilter: string = '';
  isEdited: boolean = true;
  dropdownValues: any = ['Blank Template', 'Cost Center Already Exists', 'Template 2', 'Template 3'];
  constructor(
    private toastrService: ToastrService
  ) { }

  agInit(params: any): void {
    this.masterData = params.masterData;
    this.selectedValues = params.selectedValues;
    this.ddControl.setValue(this.selectedValues);
    this.params = params;
    this.ddControl.enable();

    this.filteredValues = this.ddControl.valueChanges.pipe(
      startWith<string | string[]>(''),
      map((value) => this.updateSelectList(value)),
      map((value) => (typeof value === 'string' ? value : this.lastFilter)),
      map((filter) => this.filter(filter))
    );
  }


  refresh(): boolean {
    if (this.isEdited) {
      return false;
    }
    return true;
  }


  updateSelectList(value: any): any {
    let valueToGrid = value;
    this.isEdited = false;
    if (this.params && valueToGrid) {
      this.params.data[this.params.column.colId] = valueToGrid;
      this.params.masterData = this.masterData;
      this.params.value = valueToGrid;
      this.params.context.componentParent.customRefresh(this.params.column.colId);
      if (this.dropdownValues.length > 0) {
        for (let i = 0; i < this.dropdownValues.length; i++) {
          if (this.params.value === this.dropdownValues[i]) {
            this.sendEmail();
          }
        }
      }

    }
    if (this.params && this.ddControl.touched && (valueToGrid === '' || (typeof valueToGrid === "undefined"))) {
      this.params.data[this.params.column.colId] = '';
      this.params.masterData = this.masterData;
      this.params.value = '';
      this.params.context.componentParent.customRefresh(this.params.column.colId);
    }
    this.isEdited = true;
    return value;
  }

  filter(filter: any): any[] {
    this.lastFilter = filter;
    if (filter) {
      if (this.lastFilter.indexOf(';') > 1) {
        filter = filter.split(/[; ]+/).pop()?.trim();
      }
      return this.masterData.filter((option) => {
        return option.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      });
    }
    else {
      return this.masterData.slice();
    }
  }

  displayFn = (value: string) => {
    let displayValue = value;
    if (value) { this.ddControl.markAsTouched(); }
    return displayValue;
  }

  optionClicked(event: Event, ddValue: string, trigger: MatAutocompleteTrigger) {
    this.selectedValues = ddValue;
    this.ddControl.setValue(this.selectedValues);
    this.ddControl.markAsTouched();
    if (trigger.panelOpen) {
      trigger.closePanel();
    }
  }

  onkeyPress(e: any, trigger: MatAutocompleteTrigger) {
    if (e.keyCode === 13) {
      let valueFiltered = e.currentTarget.value;
      if (valueFiltered && valueFiltered !== null && valueFiltered.trim() !== "") {
        let value = this.masterData.filter(x => x.toLowerCase().indexOf(valueFiltered.toLowerCase()) >= 0);
        this.selectedValues = value[0];
        //this.selectedValues = ddValue;
        this.ddControl.setValue(this.selectedValues);
        this.ddControl.markAsTouched();
        if (trigger.panelOpen) {
          trigger.closePanel();
        }
        this.ddControl.setValue(this.selectedValues);
      } else {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  }

  clearText() {
    this.ddControl.setValue('');
    this.selectedValues = '';
  }

  openPanel(event: any, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    trigger.openPanel();
  }


  sendEmail() {
    let body;
    let subject;
    if(this.params.value === "Blank Template"){
      window.location.href = "mailto:" + this.params.data.email  + "?cc=TonyaZellner@iammorrison.com";
    }else{
      subject = "Communication from My Diet Manual";
      if (this.params.value === "Cost Center Already Exists") {
        body = "Cost Center Already Exists";
      } else if((this.params.value === "Template 2") || (this.params.value === "Template 3")) {
        body = "Invalid";
      }
      else  {
        body = "";
      }
      window.location.href = "mailto:" + this.params.data.email + "?subject=" + subject + "&body=" + body + "&cc=TonyaZellner@iammorrison.com";
    }
  }
}