import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreLoginFooterComponent } from './pre-login-footer.component';

@NgModule({
    declarations: [
        PreLoginFooterComponent
    ],
    exports: [
        PreLoginFooterComponent
      ],
    imports: [
        CommonModule        
    ]
})
export class PreLoginFooterModule { }
