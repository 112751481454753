<div class="footer-container">
    <div class="row">
        <footer class="footer">
            <div>
                <span>
                    <a href="https://www.morrisonhealthcare.com/privacy-policy/"
                        target="_blank">Privacy
                        Policy</a>
                </span>

                <span>
                    <a href="https://www.morrisonhealthcare.com/terms-of-use/" target="_blank">Terms
                        of Use</a>
                </span>

                <span>
                    <a href="https://privacyportal-eu-cdn.onetrust.com/dsarwebform/8394ad8c-2b46-4837-8771-cbc69779a644/31bea1f4-92c3-440b-be0e-468af4f4b1f3.html?Source=MyDietManual"
                        target="_blank">Privacy Request</a>
                </span>

                <span>
                    <a class="nav-link" href="#IContactus">Contact Us</a>
                </span>
            </div>


            <hr class="lineCss">

            <div>
                <span class="copyright">&copy; {{currentYear}} MyDietManual.com, inc. All rights reserved.</span>
            </div>
        </footer>
    </div>

</div>