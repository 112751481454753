import { Component } from '@angular/core';

@Component({
  selector: 'app-pre-login-footer',
  templateUrl: './pre-login-footer.component.html',
  styleUrls: ['./pre-login-footer.component.scss']
})
export class PreLoginFooterComponent {
  currentYear = new Date().getFullYear();
}
