import { Component, ElementRef, Input, OnChanges, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MyDietCommonService } from 'src/app/common/services/mydiet-common.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-right-content',
  templateUrl: './right-content.component.html',
  styleUrls: ['./right-content.component.scss']

})
export class RightContentComponent implements OnChanges {
  @Input() contentId: string;
  @Input() manualId: string;
  @Input() selectedSubMenu: string;
  @Input() selectedMenu: string;
  @Input() searchText: string;
  content: SafeHtml;
  @BlockUI() blockUI: NgBlockUI;
  // @ViewChild('iframe') iframe: ElementRef;

  constructor(
    private toastrService: ToastrService,
    private commonService: MyDietCommonService,
    private sanitizer: DomSanitizer) { }

  ngOnChanges(): void {
    if (this.contentId) {
      this.getToC(this.contentId);
    }
  }

  printContent(): void {
    const printWindow: any = window.open('', '_blank');
    const printDocument = printWindow.document;
    printDocument.write(this.content);
    printWindow.document.close();
    printWindow.print();
  }

  private getToC(tocId: string): void {
    this.blockUI.start('Loading...');
    this.commonService.getSelectedToC(this.manualId, tocId).subscribe((response: any) => {
      this.blobToString(response).then(htmlString => {
        this.content = this.sanitizer.bypassSecurityTrustHtml(htmlString);
        //this.loadContentIntoIframe();
      });
      this.blockUI.stop();
    },
      (error: any) => {
        this.blockUI.stop();
        this.toastrService.error('Error while fetching the Table of Content.');
      })
  }

  private blobToString(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        let result = reader.result as string;
        result = result.replace(/color\s*:\s*[^;]+';\s*/gi, '');
        result = result.replace(/"Aptos Display"/gi, '"Calibri"');
        if (this.searchText && this.searchText.toString().trim()) {
          const regex = new RegExp(`(${this.searchText.toString().trim()})`, 'gi');
          result = result.replace(regex, '<mark style="background-color:#efff00">$1</mark>');
        }
        resolve(result);
      };
      reader.onerror = reject;
      reader.readAsText(blob);
    });
  }

}