<div class="kt-portlet">
  <div class="kt-portlet__body" style="padding: 0% !important;">
    <div class="menu">
      <div class="search-container">
        <input type="text" placeholder="Search Diet Manual for..." [(ngModel)]="tocSearchQuery"
        (ngModelChange)="onTocSearchQueryChange($event)" (keydown.enter)="onTocSearch()" class="search-input">
        <i (click)="onTocSearch()" class="fas fa-search search-icon"></i>
      </div>
      <div class="search-container">
        <input type="text" placeholder="Search table of contents for..." [(ngModel)]="searchQuery"
          (input)="filterMenu()" class="search-input">
        <i class="fas fa-info-circle info-icon" title="Result will display when you type the text."></i>
      </div>
      <div *ngFor="let menuItem of filteredMenuItems; let i = index">
        <div (click)="selectMenuItem(menuItem)" [class.selected]="menuItem === selectedMenuItem"
          [class.first-time-selected]="initialSelectionMade && i === 0" class="menu-title">
          {{ menuItem.title }}
          <i class="fas expand-symbol"
            [ngClass]="{'fa-chevron-right': !menuItem.expanded, 'fa-chevron-down': menuItem.expanded}"></i>
        </div>
        <div *ngIf="menuItem.expanded" class="submenu">
          <div *ngFor="let subMenuItem of menuItem.subMenu; let submenuIndex = index">
            <div class="dividerSubMenu"></div>
            <div (click)="selectSubMenuItem(menuItem, subMenuItem); toggleSubMenuExpansion(subMenuItem)"
              [class.selected]="subMenuItem === selectedSubMenuItem"
              [class.first-time-selected]="initialSelectionMade && submenuIndex === 0" class="submenu-item">
              {{ subMenuItem.title }}
              <i *ngIf="subMenuItem.subMenu && subMenuItem.subMenu.length > 0" class="fas expand-symbol"
                [ngClass]="{'fa-chevron-right': !subMenuItem.expanded, 'fa-chevron-down': subMenuItem.expanded}"></i>
            </div>
            <div *ngIf="subMenuItem.expanded" class="nested-submenu">
              <div *ngFor="let nestedSubMenuItem of subMenuItem.subMenu">
                <div class="dividerSubMenu"></div>
                <div (click)="selectSubMenuItem(menuItem, nestedSubMenuItem)"
                  [class.selected]="nestedSubMenuItem === selectedSubMenuItem"
                  [class.first-time-selected]="initialSelectionMade && submenuIndex === 0" class="submenu-item">
                  {{ nestedSubMenuItem.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="divider"></div>
      </div>
    </div>
  </div>
</div>