<div class="head-container">
    <div class="row">
        <div class="col-md-3 col-lg-3 col-sm-12">
            <div class="logo">
                <img class ="imageHeaderStyle" src="assets/images/Nutrition Manual Across the Continuum of Care Final.png">
            </div>
        </div>
        <div class="col-lg-6 col-md- col-sm-12">
            <nav id="navbar-example2" class="navbar navbar-light bg-light px-3">                
                <div class="topnav">
                  <a class="nav-link" href="selfService/#Ihome">Home</a>
                  <a class="nav-link" href="selfService/#Iservices">Services</a>
                  <a class="nav-link" href="selfService/#IFaq">FAQ</a>
                  <a class="nav-link" href="selfService/#IContactus">Contact Us</a>
                </div>
              </nav>         
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12" style="align-self: center;">
            <button type="button" (click)="enableLogin()" class="btn btn-outline-primary btn-sm btn-css">Login</button>

           <button style="margin-left: 10px;" (click)="onSignUp()" type="button" class="btn btn-primary btn-sm btn-appheader">Sign up</button>
        </div>
       
    </div> 
    <div class="underline-block"></div>  
</div>
