import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss']
})

export class CommonModalComponent implements OnInit {
  @Input() title: string = 'Confirmation';
  @Input() bodyText: string;
  @Input() cancelText: string = 'Cancel';
  @Input() okText: string = 'Ok';
  @Input() showCancel: boolean = true;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
