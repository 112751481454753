import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MyDietCommonService } from '../../services/mydiet-common.service';
import { ToastrService } from 'ngx-toastr';
import { NewUserApprovalsService } from 'src/app/approvals/new-user-approvals/new-user-approvals.service';

@Component({
  selector: 'app-custom-validation',
  templateUrl: './custom-validation.component.html'
})
export class CustomValidationComponent implements ICellRendererAngularComp {
  //params: any;
  public cellValue: any;
  public params: any;
  public isCellValue: any;
  public disablePointerEvent: boolean = false;
  hasError: any;
  @BlockUI() blockUI: NgBlockUI;
  constructor(
    private newUserApprovalsService: NewUserApprovalsService,
    public toastrService: ToastrService,
  ) { }

  agInit(params: any): void {
    // this.params = params;
    // this.cellValue = params.costCenterNum;
    this.params = params;
    this.cellValue = params.value;
    if (this.cellValue) {
      this.isCellValue = true;
    } else {
      this.isCellValue = false;
    }

  }

  clearText() {
    this.cellValue = '';
    this.params.data[this.params.column.colId] = '';
    this.params.data.costCenterName = '';
    this.params.data.isValidCostCenter = false;
    this.params.context.componentParent.customRefresh(['costCenterName', 'isValidCostCenter']);
    this.params.context.componentParent.customRefresh(this.params.column.colId);
    this.isCellValue = false;
    this.toastrService.error('This field cannot be left empty');
  }

  onCellEdit(e: any) {
    var inp = String.fromCharCode(e.keyCode);
    var keycode = e.currentTarget.value + e.key;
    if ((/^[0-9]+$/).test(inp)) {
      return true;
    } else {
      e.preventDefault();
      return false;
    }
  }

  updateCostCenter() {
    let ccNum = this.cellValue;
    if (ccNum && ccNum.length >= 4) {
      this.blockUI.start();
      let obj = {
        id: this.params.data.id,
        costCenterNumber: ccNum
      }
      this.newUserApprovalsService.validateCostCenter(obj)
        .subscribe({
          next: (res) => {
            if(res && res.error && (res.error[0] === "The cost center entered is not valid.")){;
              this.blockUI.stop();
              this.toastrService.error('The cost center entered is not valid.');
              this.params.data[this.params.column.colId] = '';
              this.params.data.costCenterName = '';
              this.params.data.isValidCostCenter = false;
              this.params.context.componentParent.customRefresh(['costCenterName', 'isValidCostCenter']);
              this.params.context.componentParent.customRefresh(this.params.column.colId);
              if (this.cellValue.length > 0) {
                this.isCellValue = true;
              } else {
                this.isCellValue = false;
              }
            }else if (res && res.data) {
              this.params.data[this.params.column.colId] = ccNum;
              this.params.data.costCenterName = res.data.name;
              this.params.data.isValidCostCenter = true;
              this.params.context.componentParent.customRefresh(['costCenterName', 'isValidCostCenter']);
              this.params.context.componentParent.customRefresh(this.params.column.colId);
              this.isCellValue = true;
            }
            this.blockUI.stop();
          },
          error: (error) => {
            console.error(error);
            this.blockUI.stop();
            this.toastrService.error('The cost center entered is not valid.');
            this.params.data[this.params.column.colId] = '';
            this.params.data.costCenterName = '';
            this.params.data.isValidCostCenter = false;
            this.params.context.componentParent.customRefresh(['costCenterName', 'isValidCostCenter']);
            this.params.context.componentParent.customRefresh(this.params.column.colId);
            if (this.cellValue.length > 0) {
              this.isCellValue = true;
            } else {
              this.isCellValue = false;
            }
          }
        });
    } else {
      this.params.data[this.params.column.colId] = '';
      this.params.data.costCenterName = '';
      this.params.data.isValidCostCenter = false;
      this.params.context.componentParent.customRefresh(['costCenterName', 'isValidCostCenter']);
      this.params.context.componentParent.customRefresh(this.params.column.colId);
      if (this.cellValue.length > 0) {
        this.isCellValue = true;
      } else {
        this.isCellValue = false;
      }
    }

    if(this.cellValue === ''){
      this.toastrService.error('This field cannot be left empty');
    }
  }

  refresh(): boolean {
    return true;
  }


}
