import { Injectable } from '@angular/core';
import { rbacCustom } from '../models/rbac.custom.model';

@Injectable({
    providedIn: 'root'
})


export class MyDietRbacCustomService {
    private _rbacCustom: rbacCustom | null;

    get rbacCustomObj(): rbacCustom | null {
        return this._rbacCustom;
    }

    set rbacCustomObj(value: rbacCustom | null) {
        if (value) {
            this._rbacCustom = value;
        }
        else {
            this._rbacCustom = null;
        }
    }

    getUserName() {
        let rbacObj = this.rbacCustomObj;
        let userName;
       
        if (rbacObj?.user_name) {
            userName = rbacObj.user_name;
        } else if (rbacObj?.email) {
            userName = rbacObj.email;
        } else {
            return null;
        }
        return userName;
      }

}
