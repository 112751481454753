import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MyDietCommonService } from 'src/app/common/services/mydiet-common.service';
import { AppConfigService } from 'src/app/appServiceConfig';
import { environment } from 'src/environments/environment';
import { newsEditorModel, newsModel } from '../common/models/news.model';


@Injectable({
  providedIn: 'root'
})


export class NewsService {

  constructor(private http: HttpClient, private appConfig: AppConfigService, private commonService: MyDietCommonService) {
  }

  getYearFromDate(expiryDate: any) {
   let date = new Date(expiryDate);
   // let parts = expiryDate.split('-');
    //let date = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
    let year = date.getFullYear();
    return year;
  }

  getMonthNameFromDate(expiryDate: any) {
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    // let parts = expiryDate.split('-');
    // let date = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
    let date = new Date(expiryDate);
    let month = monthNames[date.getMonth()];
    let dateValue = date.getDate();
    let dateWithMonth = month + " " + dateValue;
    return dateWithMonth;
  }


  getAllSystemNews(): Observable<any> {
    const headers = this.commonService.getHeaders();
    return this.http.get(this.appConfig.getConfig().api.uri + environment.urls.getAllSystemNews, { headers });
  }

  getAllHospitalNews(facilityId: any): Observable<any> {
    const headers = this.commonService.getHeaders();
    let queryParam1 = "facilityId";
    let params = [];
    params.push({ key: queryParam1, value: facilityId });
    const url = this.commonService.replaceParamsForUrl(environment.urls.getAllHospitalNews, params);
    return this.http.get(this.appConfig.getConfig().api.uri + url, { headers });
  }

  getHeadersFileIO(): HttpHeaders {
    const authToken = this.commonService.oAuthToken;
    let headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);
    return headers;
}

  addFacilityNews(body: any) {
    const headers = this.getHeadersFileIO();
    return this.http.post(this.appConfig.getConfig().api.uri + environment.urls.FacilityNews, body, { headers });
  }

  updateFacilityNews(body: any) {
    const headers = this.getHeadersFileIO();
    return this.http.put(this.appConfig.getConfig().api.uri + environment.urls.FacilityNews, body, { headers });
  }

  addSystemNews(body: any) {
    const headers = this.getHeadersFileIO();
    return this.http.post(this.appConfig.getConfig().api.uri + environment.urls.SystemNews, body, { headers });
  }

  updateSystemNews(body: any) {
    const headers = this.getHeadersFileIO();
    return this.http.put(this.appConfig.getConfig().api.uri + environment.urls.SystemNews, body, { headers });
  }
  
  deleteSystemNews(newsId:number)
  {
    const headers = this.commonService.getHeaders();
    let queryParam1 = "SystemNewsId";
    let params = [];
    params.push({ key: queryParam1, value: newsId });
    const url = this.commonService.replaceParamsForUrl(environment.urls.deleteSystemNews, params);
    return this.http.delete(this.appConfig.getConfig().api.uri + url, { headers });    
  }

  deleteFacilityNews(newsId:number)
  {
    const headers = this.commonService.getHeaders();
    let queryParam1 = "FaciityNewsId";
    let params = [];
    params.push({ key: queryParam1, value: newsId });
    const url = this.commonService.replaceParamsForUrl(environment.urls.deleteFacilityNews, params);
    return this.http.delete(this.appConfig.getConfig().api.uri + url, { headers });
  }

}