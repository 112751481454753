import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreLoginComponent } from './pre-login.component';
import { PreLoginHeaderModule } from '../pre-login-header/pre-login-header.module';
import { PreLoginFooterModule } from '../pre-login-footer/pre-login-footer.module';
import { BlockUIModule } from 'ng-block-ui';
import { PreLoginRoutingModule } from './pre-login-routing.module';
import {MatExpansionModule} from '@angular/material/expansion';

@NgModule({
    declarations: [
        PreLoginComponent
    ],
    exports: [PreLoginComponent],
    imports: [
        CommonModule,
        PreLoginFooterModule,
        PreLoginHeaderModule,
        BlockUIModule,
        PreLoginRoutingModule,
        MatExpansionModule
    ]
})
export class PreLoginModule { }
