import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { Routes } from "@angular/router";
import { AuthGuard } from "src/app/common/guards/authGuards/can-activate.guards";
import { ViewDietManualsComponent } from "./diet-manuals/view-diet-manuals/view-diet-manuals.component";

const routes: Routes = [
    {
      path: '',
      component: ViewDietManualsComponent,
      canActivate: [AuthGuard],
    }    
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class ManualsRoutingModule { }
  