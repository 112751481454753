import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";
import { QuillModule} from 'ngx-quill';
import { BlockUIModule } from 'ng-block-ui';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { SystemNewsComponent } from "./system-news/system-news.component";
import { FacilityNewsComponent } from "./facility-news/facility-news.component";
import { NewsDisplayComponent } from './news-display/news-display.component';
import { NewsDisplayModalComponent } from './news-display-modal/news-display-modal.component';
import { NewsEditorComponent } from './news-editor/news-editor.component';
import { MatSelectModule } from '@angular/material/select'
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [
        SystemNewsComponent,
        FacilityNewsComponent,
        NewsDisplayComponent,
        NewsDisplayModalComponent,
        NewsEditorComponent
    ],
    imports: [
        NgSelectModule,
        CommonModule,
        FormsModule,
        QuillModule,
        BlockUIModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatButtonModule,
        MatTooltipModule

    ],
    exports: [
        SystemNewsComponent,
        FacilityNewsComponent
    ]
})
export class NewsModule { }