import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-pre-login',
  templateUrl: './pre-login.component.html',
  styleUrls: ['./pre-login.component.scss']
})
export class PreLoginComponent implements OnInit {
  panelOpenState = false;
  showFaqSection = false;

  @BlockUI('login-page') blockUI: NgBlockUI;

  constructor( private router: Router) {    
  }

  ngOnInit(): void {   
    this.blockUI.stop();
  }

  expandFaq(){
    this.showFaqSection = true;
  }

  hideFaq(){
    this.showFaqSection = false;
  }

  onSignUp(){
    this.router.navigate(['/signup']);
  }

}
