import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BlockUIModule } from 'ng-block-ui';
import { LeftMenuComponent } from "./left-menu/left-menu.component";
import { RightContentComponent } from "./right-content/right-content.component";

@NgModule({
    declarations: [
        LeftMenuComponent,
        RightContentComponent
    ],
    exports: [
        LeftMenuComponent,
        RightContentComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        BlockUIModule
    ]
})
export class CustomManualsViewModule { }