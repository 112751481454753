<mat-form-field style="margin-top: -23px;" >
  <mat-label class="dropdownLabel">Choose a Template</mat-label>
  <span matSuffix *ngIf="!selectRef.value"><i
    class="fa fa-caret-down"></i></span>
    <input #selectRef class="customFont" type="text" matInput [matAutocomplete]="auto" [formControl]="ddControl"
      #trigger="matAutocompleteTrigger"  (keyup) = "onkeyPress($event, trigger)" (click)="openPanel($event, trigger)" />
    <span matSuffix *ngIf="selectRef.value && disablePointerEvent === false" (click)="clearText()"><i
        class="fas fa-times"></i></span>
  </mat-form-field>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option class="customFont" (click)="optionClicked($event, item,trigger);$event.stopPropagation();" *ngFor="let item of filteredValues | async" [value]="selectedValues">
      <div >
        {{item}}
      </div>
    </mat-option>
  </mat-autocomplete>