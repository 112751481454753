export const COMPASS_EMAIL_EXTENSIONS = [
  'COMPASS-USA.COM',
  'IAMMORRISON.COM',
  'COMPASSDIGITAL.IO',
  'COREWORKS1.COM',
  'FOODBUY.COM',
  'CROTHALL.COM',
  'IAMTOUCHPOINT.COM',
  'MORRISONLIVING.COM',
  'UNIDINE.COM',
  'CCL-HG.COM'
];

export const NO_AUTH_ROUTES = [
  'IHOME',
  'ISERVICES',
  'IFAQ',
  'ICONTACTUS'
];

export const ALLOWED_FILE_FORMATS = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/pdf',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-excel'
]

export const ALLOWED_FILE_FORMATS_MANUALS = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]

export const ALLOWED_FILE_FORMATS_PATIENT_EDUCATION = [
  'application/pdf'
]


export const MENU_TILES = {
  "MyDietMenuTiles": [
    {
      "title": 'Facilities',
      "stgSecuredObject": 'DIETMANUAL_ADMINISTRATION',
      "order": 1,
      "icon": 'fa fa-light fa-house-user',
      "route": '/administration/facility',
    },
    {
      "title": 'Users',
      "stgSecuredObject": 'DIETMANUAL_ADMINISTRATION',
      "order": 2,
      "icon": 'fa fa-users',
      "route": '/administration/users',
    },
    {
      "title": 'Subscription Templates',
      "stgSecuredObject": 'User Administration',
      "order": 3,
      "icon": 'fa fa-sharp fa-regular fa-calendar-check',
      "route": '/administration/subscriptions',
    },
    {
      "title": 'Page Content',
      "stgSecuredObject": 'User Administration',
      "order": 4,
      "icon": 'fa fal fa-folder',
      "route": '/administration/pagecontent',
    },
    {
      "title": 'Diet Manual',
      "stgSecuredObject": 'User Administration',
      "order": 5,
      "icon": 'fa fa-sharp fa-regular fa-carrot',
      "route": '/administration/dietmanual',
    },
    {
      "title": 'Patient Education',
      "stgSecuredObject": 'User Administration',
      "order": 6,
      "icon": 'fas fa-chalkboard-teacher',
      "route": '/administration/patienteducation',
    }
  ]
}

export const MANUAL_TYPES = [
  {
    "id": 1,
    "manualType": "Acute Care"
  },
  {
    "id": 2,
    "manualType": "Long Term Care"
  },
  {
    "id": 3,
    "manualType": "Nutrition Manual Across the Continuum of Care"
  }
];
export const CALCULATION_BLOCKS_DATA = {
  data: {
    blocks: [
      { value: 'Percent Ideal Body Weight' },
      { value: 'Percent Usual Body Weight' },
      { value: 'Percent Recent Weight Change' },
      { value: 'Body Mass Index' },
      { value: 'Wrist Measurement' },
      { value: 'Mifflin-St.Jeor for Women' },
      { value: 'Mifflin-St.Jeor for Men' },
      { value: 'Penn State 2003 B (Women)' },
      { value: 'Penn State 2003 B (Men)' },
      { value: 'Penn State 2010 (Women)' },
      { value: 'Penn State 2010 (Men)' },
    ]
  },
  error: ''
};

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/im;
