import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable,catchError,map, of, throwError } from 'rxjs';
import { AppConfigService } from 'src/app/appServiceConfig';
import { environment } from 'src/environments/environment';
import { facilityListAPIResponse, facilityListModel } from '../models/facilitylist.model';
//import { rbac_sys,cost_center_list } from '../models/dummyData';


@Injectable({
  providedIn: 'root'
})

export class MyDietCommonService {

  public _oAuthToken: string | null = null;
  public _oAuthTokenValue: string  = "";

  public facilityChange$: BehaviorSubject<facilityListModel> = new BehaviorSubject<any>(null);
  facilityChange = this.facilityChange$.asObservable();
  selfServiceDisable: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  constructor(private http: HttpClient,private appConfig :AppConfigService) { 
    
  }


  disableSelfService() {
    this.selfServiceDisable.next(true);
  }

  submitSignUp(body: any): Observable<any> {
    const headers = this.getHeadersWithoutToken();
    return this.http.post(this.appConfig.getConfig().api.uri + environment.urls.userRegistration, body, { headers });
  }

  getAllApprovals(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(this.appConfig.getConfig().api.uri + environment.urls.getAllApproval, { headers });
  }

  deleteApprovalRecord(obj: any): Observable<any> {
    const headers = this.getHeaders();
    return this.http.put(this.appConfig.getConfig().api.uri + environment.urls.rejectApprovalData,obj, { headers });
  }

  approveUserRecord(body: any): Observable<any> {
    const headers = this.getHeaders();
    return this.http.post(this.appConfig.getConfig().api.uri + environment.urls.approveUserData, body, { headers });
  }

  getRbacProfileCustom():Observable<any>{
    const headers = this.getHeaders();
    // return of(rbac_sys);
    return this.http.get(this.appConfig.getConfig().api.uri + environment.urls.rbacProfileMyDiet,{headers});
  }

  getFacilitiesList(username: any):Observable<facilityListAPIResponse | any>
  {
   // return of(cost_center_list)
    const headers = this.getHeaders();
    let queryParam1 = "userName";
    let params = [];
      params.push({key: queryParam1, value: username});
    const url = this.replaceParamsForUrl(environment.urls.getFacilitiesList, params);
    return this.http.get<facilityListAPIResponse>(this.appConfig.getConfig().api.uri + url,{headers}); 
  }

  get oAuthToken(): string | null {
    return this._oAuthToken;
  }

  set oAuthToken(value: string | null) {
    if (value) {
      this._oAuthToken = value;
    } else {
      this._oAuthToken = null;
    }
  }

  get oAuthTokenValue(): string {
    return this._oAuthTokenValue;
  }

  set oAuthTokenValue(value: string) {
    if (value) {
      this._oAuthTokenValue = value;
    } else {
      this._oAuthTokenValue = "";
    }
  }

  getHeadersWithoutToken(): HttpHeaders {
    const authToken = this.oAuthTokenValue;  
    let headers = new HttpHeaders().
    set('Content-Type', 'application/json',).set('Authorization', `Bearer ${authToken}`).
    set('Access-Control-Allow-Origin', '**');
    return headers;  
  }

  getHeaders(): HttpHeaders {
    const authToken = this.oAuthToken;  
    let headers = new HttpHeaders().set('Content-Type', 'application/json',).set('Authorization', `Bearer ${authToken}`).set('Access-Control-Allow-Origin', '**');
    return headers;  
  }

  replaceParamsForUrl(url: string, params: any){
    let customUrl;
    let tempUrl = url;
    for (let valueParams of params) {
        if(tempUrl.indexOf(valueParams.key)> -1){
            let value= "{" + valueParams.key + "}";
            tempUrl = tempUrl.replace(value, valueParams.value);
            customUrl = tempUrl;
        }else {
            customUrl = url;
        }
    }
    return customUrl;
  }
  
  validateOrder(value: any) {
    let errMsg;
    if (!(/^\d*\.?\d*$/.test(value))) {
      errMsg= 'Order should be numeric.'
    }
    else if (value && !Number.isInteger(Number(value))) {
      errMsg = 'Order should be whole number and less than 1000000.'
    }
    else if (value && Number(value) <= 0) {
    errMsg = 'Order should be more than 0.'

    }
    else if (value && Number(value) > 1000000) {
     errMsg = 'Order should be less than 1000000.'
    }
    else
    {
      errMsg = null;
    }
    return errMsg;
  }

  getBadgeCount(): Observable<any> {       
    const headers = this.getHeaders();
    return this.http.get(this.appConfig.getConfig().api.uri + environment.urls.getBadgeCount, { headers })   
      .pipe(
        map((response: any) => {
          if ( response && response.data){           
            return response
          } else {
            return null;
          }
        }),
        catchError((err : any) =>{
          console.error("Error" + err);
          return of([]);
        }
            
        )
      );
    }
}