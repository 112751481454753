import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyDietOnlyNumbersDirective } from './mydiet-only-number.directive';


@NgModule({
  declarations: [
    MyDietOnlyNumbersDirective
  ],
  exports: [
    MyDietOnlyNumbersDirective
  ],
  imports: [
    CommonModule
  ]
})
export class DirectivesModule { }
