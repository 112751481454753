import { MetadataModel } from "./metadata.model";

export interface manualModel {
  id: number,
  manualName: string,
  status: string,
  liveDate: string,
  manualType: string
}
export interface manualSectionsModel {
  id: number,
  manualId: number,
  sectionName: string,
  order: number,
  modifiedDate: string,
  documentFile: string
}

export interface manualTypeModel {
  id: number,
  manualType: string,
}

export interface manualSectionistAPIResponse {
  data: manualSectionsModel[],
  error: string[]
  metadata: MetadataModel
}
export interface manualListAPIResponse {
  data: manualModel[],
  error: string[]
  metadata: MetadataModel
}

export interface Toc {
  id: number;
  name: string;
  orderId: number;
  parentId: number;
  subMenu: Toc[];
}

export interface Section {
  manualId: number,
  fileName: string;
  id: number;
  sectionName: string;
  order: number;
  manualToc: Toc[];
}

export interface LiveManualData {
  manualName: string;
  id: number;
  manualType:number;
  manualDocumentModel: Section[];
}

export interface MetaData {
  http_Status_Code: string;
  resultCount: number;
  status: string;
  version: string;
}

export interface LiveManualResponse {
  data: LiveManualData;
  error: any;
  metaData: MetaData;
}

export interface ManualSubMenuItem {
  title: string;
  contentId: string;
  expanded: boolean;
}

export interface ManualMenuItem {
  title: string;
  subMenu: ManualSubMenuItem[];
  expanded: boolean;
}

export const getEmptyLiveManualResponse = (): LiveManualResponse => {
  return {
    "data" : {
      "manualName": "",
      "id": 0,
      "manualType": 3,
      "manualDocumentModel": []
    },
    "error": null,
    "metaData": {
      "http_Status_Code": "",
      "resultCount": 0,
      "status": "",
      "version": ""
    }
  }};