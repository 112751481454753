<div class="row" *blockUI="'addendum-data'">
    <div class="row">
        <div class="col-sm-12 col-lg-12 col-md-12">
            <stg-ngx-ag-grid height="45vh" [gridOptions]="gridOptions" (onGridReady)="onGridReady($event)">
            </stg-ngx-ag-grid>
          
        </div>
        <div *ngIf="!isUser" class="col-lg-12 col-md-12 col-sm-12 document-upload-btn">
            <button [disabled]="!isValidRoleAndSubscribed" type="button" (click)="uploadDocument()" class="btn btn-outline-primary btn-sm btn-css">
                <i class="fal fa-upload"></i> Upload Document</button>
        </div>
    </div>   
</div>