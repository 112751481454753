<app-pre-login-header></app-pre-login-header>
<div class="page-container">
    <div class="row">
        <div class="col-sm-12">
            <div class="kt-portlet">
                <div class="kt-portlet__body home-page-container">
                    <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="0"
                        class="scrollspy-example" tabindex="0">
                        <div class="row">
                            <div class="section">
                                <div class="container" style="display:flex;width:100%">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="headerContent">
                                                <div class="row">
                                                    <h1 id="Ihome" class="title">Welcome to</h1>
                                                </div>
                                                <div class="row">
                                                    <h1 class="title">My Diet Manual</h1>
                                                </div>
                                                <div class="row">
                                                    <p class="headerDescription">
                                                        Our mission is to provide you ease of access to
                                                        pertinent diet information that is both easy to
                                                        navigate and current with today's growing
                                                        nutrition evidence.
                                                    </p>
                                                </div>
                                                <div class="row">
                                                    <button type="button" class="btn btn-primary btn-sm btn-appheader"
                                                        (click)="onSignUp()">Sign
                                                        up</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="headerImage">
                                                <div class="imagecss">
                                                    <img class="headerImageStyle"
                                                        src="assets/images/DietNutrition.png">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

            
                            
                        </div>

                        <div class="faqsection">
                            <div class="container">
                                <div class="row">
                                    <div id="Iservices">
                                        <div class="section" style="padding: 10px;">
                                            <div class="container">
                                                <div class="row" style="text-align:center">
                                                    <h4 class="heading">Our Services
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="cardCss">
                                        <div class="column">
                                            <div class="card">
                                                <p style="font-weight: bold;">Nutrition Care & Facility Specific Manuals 
                                                <p>
                                                <p>Information for health care facilities
                                                    regarding diet liberalization, general
                                                    guidance for diet manual
                                                    implementation, process for annual
                                                    diet manual review and
                                                    implementation, regulations,
                                                    disaster preparedness, planning a
                                                    house diet, obsolete diets and diet
                                                    terminology, and corrections.</p>
                                            </div>
                                        </div>

                                        <div class="column">
                                            <div class="card">
                                                <p style="font-weight: bold;">Patient Education
                                                <p>
                                                <p>This section includes all nutrition
                                                    therapies and menus available in the
                                                    NCM<sup>&reg;</sup>. Handouts are created at a
                                                    6th-grade reading level to be
                                                    understandable for as many clients/
                                                    patients as possible.</p>
                                            </div>
                                        </div>

                                        <div class="column">
                                            <div class="card">
                                                <p style="font-weight: bold;">Patient Assessment Tools
                                                <p>
                                                <p>The calculators are useful when
                                                    performing a nutritional assessment
                                                    or planning nutrition intervention.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="faqSection">
                            <div class="container">
                                <div class="section" style="padding-top: 4%;">
                                    <div class="row" style="text-align:center">
                                        <h4 id="IFaq" class="heading">FAQ
                                        </h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="accordianCss">
                                        <mat-accordion>
                                            <mat-expansion-panel class="panel" (opened)="panelOpenState = true"
                                                (closed)="panelOpenState = false" [expanded]="true">
                                                <mat-expansion-panel-header style="background:transparent;">
                                                    <mat-panel-title class="panelHeaderCss">
                                                        How do I start a new annual subscription to MyDiet Manual for my
                                                        facility?
                                                    </mat-panel-title>

                                                </mat-expansion-panel-header>
                                                <p class="panelCss">Identify the person to be the Admin for the MyDiet
                                                    Manual account.
                                                    (Review the following questions for more
                                                    info on the Admin role.) The Admin will initiate the initial
                                                    subscription for your facility by clicking on Register
                                                    Your Facility on the top right of the Home page. Follow the prompts.
                                                    The
                                                    Admin will be asked to enter the
                                                    facility's Pcard to Subscribe . You can also email<a
                                                        href="mailto:MyNutritionResources@IamMorrison.com.">
                                                        MyNutritionResources@IamMorrison.com</a> with questions.</p>
                                            </mat-expansion-panel>
                                            <mat-expansion-panel class="panel" (opened)="panelOpenState = true"
                                                (closed)="panelOpenState = false">
                                                <mat-expansion-panel-header style="background:transparent;">
                                                    <mat-panel-title class="panelHeaderCss">
                                                        Who should be the Admin for my facility s MyDiet Manual
                                                        subscription?
                                                    </mat-panel-title>

                                                </mat-expansion-panel-header>
                                                <p class="panelCss">Typically, your Nutrition Care Manager, Clinical
                                                    Nutrition Manager,
                                                    lead RDN, Food Service Director/Director of Dining Services,
                                                    or other appropriate manager in your Food and Nutrition Services
                                                    Department should be the Admin.</p>
                                            </mat-expansion-panel>
                                            <mat-expansion-panel class="panel" (opened)="panelOpenState = true"
                                                (closed)="panelOpenState = false">
                                                <mat-expansion-panel-header style="background:transparent;">
                                                    <mat-panel-title class="panelHeaderCss">
                                                        As Admin, what is my responsibility/role?
                                                    </mat-panel-title>

                                                </mat-expansion-panel-header>
                                                <p class="panelCss">The Admin has the responsibility to pay the annual
                                                    subscription using the facility’s Pcard.
                                                    They also create the General Facility User Name and Password that
                                                    will be used by all who use this tool.
                                                    They upload facility specific diet addendums and update the “News”
                                                    section that the general user will see when logging into the MyDiet
                                                    Manual.</p>
                                            </mat-expansion-panel>
                                            <mat-expansion-panel class="panel" (opened)="panelOpenState = true"
                                                (closed)="panelOpenState = false">
                                                <mat-expansion-panel-header style="background:transparent;">
                                                    <mat-panel-title class="panelHeaderCss">
                                                        As General User, what do I have access to when I log into MyDiet
                                                        Manual?
                                                    </mat-panel-title>

                                                </mat-expansion-panel-header>
                                                <p class="panelCss">When the general user logs in, they have access to
                                                    the Acute and Long Term Care diet manuals,
                                                    with the ability to search a specific word or topic (search engine
                                                    is a NEW FEATURE!!).
                                                    Additionally, the user can enter figures to determine a patient’s
                                                    Percent Ideal Body Weight,
                                                    Body Mass Index (BMI), and many other calculations. The general user
                                                    also has access to the Patient Education Materials.
                                                    When a RDN downloads one of the patient education documents to their
                                                    computer, they now have the ability to add their contact
                                                    information and facility logo to the document (NEW FEATURE!!).</p>
                                            </mat-expansion-panel>
                                            <mat-expansion-panel class="panel" (opened)="panelOpenState = true"
                                                (closed)="panelOpenState = false">
                                                <mat-expansion-panel-header style="background:transparent;">
                                                    <mat-panel-title class="panelHeaderCss">
                                                        How many Admins can be assigned to each Facility's account?
                                                    </mat-panel-title>

                                                </mat-expansion-panel-header>
                                                <p class="panelCss">There can be only one Admin per account; however,
                                                    an Admin can oversee multiple Facilities if needed.
                                                    This works well for System managers, etc.</p>
                                            </mat-expansion-panel>
                                            <div *ngIf="showFaqSection">
                                                <mat-expansion-panel class="panel" (opened)="panelOpenState = true"
                                                    (closed)="panelOpenState = false">
                                                    <mat-expansion-panel-header style="background:transparent;">
                                                        <mat-panel-title class="panelHeaderCss">
                                                            As Admin, how do I receive a receipt when activating or
                                                            renewing the annual subscription?
                                                        </mat-panel-title>

                                                    </mat-expansion-panel-header>
                                                    <p class="panelCss">The Admin will automatically receive a receipt
                                                        which will be sent to the email address in their profile at time
                                                        of purchase. If not received, email
                                                        <a href="mailto:MyNutritionResources@IamMorrison.com.">
                                                            MyNutritionResources@IamMorrison.com</a>
                                                    </p>
                                                </mat-expansion-panel>
                                                <mat-expansion-panel class="panel" (opened)="panelOpenState = true"
                                                    (closed)="panelOpenState = false">
                                                    <mat-expansion-panel-header style="background:transparent;">
                                                        <mat-panel-title class="panelHeaderCss">
                                                            How will I know my annual subscription is about to expire?
                                                        </mat-panel-title>

                                                    </mat-expansion-panel-header>
                                                    <p class="panelCss">The Admin will receive a reminder email for
                                                        renewal one month and two weeks in advance of the expiration
                                                        date.</p>
                                                </mat-expansion-panel>
                                                <mat-expansion-panel class="panel" (opened)="panelOpenState = true"
                                                    (closed)="panelOpenState = false">
                                                    <mat-expansion-panel-header style="background:transparent;">
                                                        <mat-panel-title class="panelHeaderCss">
                                                            If our subscription expires, will we lose all the diet
                                                            addendums and customization we’ve created in our account?
                                                        </mat-panel-title>

                                                    </mat-expansion-panel-header>
                                                    <p class="panelCss">No. As soon as the subscription is paid, your
                                                        account will be up and running immediately without interruption.
                                                    </p>
                                                </mat-expansion-panel>
                                                <mat-expansion-panel class="panel" (opened)="panelOpenState = true"
                                                    (closed)="panelOpenState = false">
                                                    <mat-expansion-panel-header style="background:transparent;">
                                                        <mat-panel-title class="panelHeaderCss">
                                                            I am the Admin and will be changing roles/facilities. How do
                                                            I transfer my Admin role to my replacement?
                                                        </mat-panel-title>

                                                    </mat-expansion-panel-header>
                                                    <p class="panelCss">(New Feature!!) Log into your Admin account.
                                                        After choosing your hospital, click on the blue button “Change
                                                        Admin”. On the next screen, the new Admin will set their email
                                                        and password to complete this process. Once complete, only the
                                                        new Admin will be able to log in and access the facility’s
                                                        account.</p>
                                                </mat-expansion-panel>
                                                <mat-expansion-panel class="panel" (opened)="panelOpenState = true"
                                                    (closed)="panelOpenState = false">
                                                    <mat-expansion-panel-header style="background:transparent;">
                                                        <mat-panel-title class="panelHeaderCss">
                                                            The Admin on our MyDiet Manual account is no longer at our
                                                            facility. How do I change the Admin for our existing
                                                            account?
                                                        </mat-panel-title>

                                                    </mat-expansion-panel-header>
                                                    <p class="panelCss">Since the current Admin is unavailable,
                                                        determine who the new Admin will be. (Recommendations for who
                                                        should be in this role are above.) The new Admin will click on
                                                        "Register Your Facility" on the top right of the Home page.
                                                        Follow the prompts. (Stop when you are asked to enter a SAP,
                                                        because your account already exists.)
                                                        Finally, email <a
                                                            href="mailto:MyNutritionResources@IamMorrison.com.">
                                                            MyNutritionResources@IamMorrison.com</a> with your
                                                        SAP/Facility Name and request to switch out the Admin. Support
                                                        will respond with confirmation that the request has been filled.
                                                    </p>
                                                </mat-expansion-panel>
                                                <mat-expansion-panel class="panel" (opened)="panelOpenState = true"
                                                    (closed)="panelOpenState = false">
                                                    <mat-expansion-panel-header style="background:transparent;">
                                                        <mat-panel-title class="panelHeaderCss">
                                                            A NEW FEATURE for the diet manual section is that I now have
                                                            the ability to search a word/topic. Once the application
                                                            locates the section or page with my word/topic, how do I
                                                            locate it on the actual page/section?
                                                        </mat-panel-title>

                                                    </mat-expansion-panel-header>
                                                    <p class="panelCss">On your computer keyboard, click on Control F. A
                                                        search field will pop up on the top left or right of your
                                                        screen, depending on your browser. Type the word/topic in this
                                                        pop up box and your word/topic will be highlighted on the page
                                                        and throughout the section.</p>
                                                </mat-expansion-panel>
                                                <mat-expansion-panel class="panel" (opened)="panelOpenState = true"
                                                    (closed)="panelOpenState = false">
                                                    <mat-expansion-panel-header style="background:transparent;">
                                                        <mat-panel-title class="panelHeaderCss">
                                                            What file type is accepted when uploading the Facility
                                                            Specific Diet Addendums?
                                                        </mat-panel-title>

                                                    </mat-expansion-panel-header>
                                                    <p class="panelCss">docx, doc, and pdf files</p>
                                                </mat-expansion-panel>
                                            </div>
                                        </mat-accordion>
                                        <div *ngIf="!showFaqSection">
                                            <button style="margin-top: 20px; float:right;width:10%" type="button"
                                                class="btn btn-outline-primary btn-sm btn-css" (click)="expandFaq()">See
                                                more</button>
                                        </div>
                                        <div *ngIf="showFaqSection">
                                            <button style="margin-top: 20px; float:right;width:10%" type="button"
                                                class="btn btn-outline-primary btn-sm btn-css" (click)="hideFaq()">See
                                                less</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="section" style="padding: 10px;">
                            <div class="container">
                                <div class="row" style="text-align:center">
                                    <h4 id="IContactus" class="heading">Contact Us
                                    </h4>
                                </div>
                            </div>
                        </div>

                        <div class="section" style="padding: 10px;">
                            <div class="container">
                                <div class="row" style="text-align:center">
                                    <h4 class="contactHeading">To order a hard copy diet manual in the event of power
                                        failure, go to:
                                    </h4>
                                </div>
                            </div>
                        </div>


                        <div class="section">
                            <div class="container">
                                <div class="row">
                                    <div class="cardCssColumn">
                                        <div class="contactColumn">
                                            <div class="contactCard">
                                                <p style="font-weight: bold;">Morrison Healthcare Accounts</p>
                                                <i class="far fa-globe" style="margin-right:5px"></i><a
                                                    href="https://www.MHCPrints.com/" target="_blank"
                                                    style="margin-right:5px">www.MHCPrints.com</a>(use Chrome)
                                            </div>
                                        </div>

                                        <div class="contactColumn">
                                            <div class="contactCard">
                                                <p style="font-weight: bold;">Morrison Living Accounts
                                                </p>
                                                <i class="far fa-globe" style="margin-right:5px"></i><a
                                                    href="https://www.morrisonhealthcare.com/" target="_blank"
                                                    style="margin-right:5px">www.morrisonhealthcare.com</a>(use Chrome)
                                            </div>
                                        </div>

                                        <div class="contactColumn">

                                            <div class="contactCard">
                                                <div class="row"></div>
                                                <p style="font-weight: bold;">For questions or support
                                                </p>
                                                <div style="display:inline-flex;">
                                                    <i class="far fa-envelope">&nbsp; <a
                                                            href="mailto:MyNutritionResources@IamMorrison.com.">MyNutritionResources@IamMorrison.com.</a></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>
<app-pre-login-footer></app-pre-login-footer>