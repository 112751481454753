<div *ngFor="let news of newsData" class="row card-block-padding">
    <div class="col-sm-3 col-lg-3 col-md-3">
        <div class="card text-white bg-success mb-3 card-position-custom card-props-custom">
            <div class="card-body">
                <h6 style="text-align: center; font-weight: 600;" class="card-text">{{news.daywithMonth}}</h6>
                <h5 style="text-align: center; font-weight: 600;" class="card-text">{{news.year}}</h5>
            </div>
        </div>
    </div>

    <div class="col-sm-9 col-lg-9 col-md-9">
        <div class="row news-custom-header">
            <h5> {{news.title}} </h5>
        </div>
        <div class="row">
            <span class='ellipse two-lines' [innerHTML]="news.content">
            </span>
        </div>
        <div class="row news-options">
            <span class="spanClass" (click)="openNewsModal(news)"> Learn more </span>
            <span *ngIf="isValid" class="spanClass" (click)="editInitiated(news)"> Edit </span>
            <!-- <span *ngIf="!isUser" class="spanClass"> Delete </span> -->
        </div>
    </div>
</div>