import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { LiveManualData, LiveManualResponse, ManualMenuItem, manualSectionsModel, ManualSubMenuItem, Section, Toc } from 'src/app/common/models/manual.model';
import { MyDietCommonService } from 'src/app/common/services/mydiet-common.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})

export class LeftMenuComponent implements OnChanges {
  @Input() liveManualResponse: LiveManualResponse;
  @Output() subMenuSelected = new EventEmitter<ManualSubMenuItem>();
  @Output() menuSelected = new EventEmitter<ManualMenuItem>();
  @Output() searchTextInitiated = new EventEmitter<string>();

  public isSearchButtonClicked: boolean = false; // Flag
  public initialSelectionMade: boolean = false; // Flag
  selectedMenuItem: ManualMenuItem | null = null;
  selectedSubMenuItem: ManualSubMenuItem | null = null;
  public firstTimeLoaded: LiveManualResponse;

  tocSearchQuery: string = '';
  searchQuery: string = '';
  manualMenuItems: ManualMenuItem[] | null = [];
  filteredMenuItems: any = [];

  constructor(    
    private toastrService: ToastrService) { }

  ngOnInit(): void {
    if (this.liveManualResponse && this.liveManualResponse.data && this.liveManualResponse.data.id
      && this.liveManualResponse.data.manualDocumentModel && this.liveManualResponse.data.manualDocumentModel.length > 0) {
      this.firstTimeLoaded = this.liveManualResponse;
    }

  }

  ngOnChanges(): void {
    if (this.liveManualResponse && this.liveManualResponse.data && this.liveManualResponse.data.id
      && this.liveManualResponse.data.manualDocumentModel && this.liveManualResponse.data.manualDocumentModel.length > 0) {
      this.setupMenuItems(this.liveManualResponse);
    }
  }

  onTocSearch(): void {
    if (this.tocSearchQuery && this.tocSearchQuery.toString().trim() && this.tocSearchQuery.length > 2) {
      this.isSearchButtonClicked = true;
      this.searchTextInitiated.emit(this.tocSearchQuery);
    } else {
      this.toastrService.warning('Please enter at least 3 characters');
    }
  }

  onTocSearchQueryChange(query: string) {
    this.tocSearchQuery = query;
    if (this.tocSearchQuery === '' && this.isSearchButtonClicked) {
      this.isSearchButtonClicked = false;
      this.onTocSearchQueryCleared();
    }
  }

  selectMenuItem(menuItem: ManualMenuItem): void {   
    menuItem.expanded = !menuItem.expanded;
    this.initialSelectionMade = false;
  }

  selectSubMenuItem(menuItem: ManualMenuItem, subMenuItem: ManualSubMenuItem): void {
    this.selectedMenuItem = menuItem;
    this.selectedSubMenuItem = subMenuItem;
    this.subMenuSelected.emit(subMenuItem);
    this.menuSelected.emit(menuItem);
    this.initialSelectionMade = false;
  }

  filterMenu(): void {
    const query = this.searchQuery.toLowerCase();
  
    if(query === '' && this.manualMenuItems) {
      this.initialisation(this.manualMenuItems);
      return;
    }

    const filterSubMenu = (subMenu: any[]): any[] => {
      return subMenu.map(subMenuItem => {
        const filteredNestedSubMenu = filterSubMenu(subMenuItem.subMenu);
        const matchesQuery = subMenuItem.title.toLowerCase().includes(query);
        return {
          ...subMenuItem,
          subMenu: filteredNestedSubMenu,
          expanded:  matchesQuery
        };
      }).filter(subMenuItem => subMenuItem.subMenu.length > 0 || subMenuItem.title.toLowerCase().includes(query));
    };
  
    this.filteredMenuItems = this.manualMenuItems?.map(menuItem => {
      const filteredSubMenu = filterSubMenu(menuItem.subMenu);
      const matchesQuery = menuItem.title.toLowerCase().includes(query);
      return {
        ...menuItem,
        subMenu: filteredSubMenu,
        expanded: filteredSubMenu.length > 0 || matchesQuery
      };
    }).filter(menuItem => menuItem.subMenu.length > 0 || menuItem.title.toLowerCase().includes(query));
  }
  // filterMenu(): void {
  //   const query = this.searchQuery.toLowerCase();
  //   this.filteredMenuItems = this.manualMenuItems?.map(manualMenuItems => {
  //     const filteredSubMenu = manualMenuItems?.subMenu.filter(subMenuItem => subMenuItem?.title?.toLowerCase().includes(query));
  //     return {
  //       ...manualMenuItems,
  //       subMenu: filteredSubMenu,
  //       expanded: filteredSubMenu.length > 0
  //     };
  //   }).filter(menuItem => menuItem?.subMenu?.length > 0 || menuItem?.title?.toLowerCase().includes(query));
  // }

  toggleSubMenuExpansion(subMenuItem: ManualSubMenuItem): void {
    subMenuItem.expanded = !subMenuItem.expanded;
  }

  private onTocSearchQueryCleared() {
    this.searchTextInitiated.emit(undefined);   
  }

  private setupMenuItems(liveManualResponse: LiveManualResponse): void {
    this.manualMenuItems = this.convertToMenuStructure(liveManualResponse.data);
  this.initialisation(this.manualMenuItems);
    this.initialSelectionMade = true;
  }

  private convertToMenuStructure(liveManualData: LiveManualData): any[] {
    return liveManualData.manualDocumentModel.map((section: Section) => ({
      title: section.sectionName,
      subMenu: this.mapTocToSubMenu(section.manualToc),
      expanded: false
    }));
  }

  private initialisation(manualMenuItems:any): void {
    this.filteredMenuItems = [...manualMenuItems];
    if (this.filteredMenuItems.length > 0) {
      this.filteredMenuItems[0].expanded = true;
      this.selectedMenuItem = this.filteredMenuItems[0];
      this.selectedSubMenuItem = this.filteredMenuItems[0].subMenu.length > 0 ? this.filteredMenuItems[0].subMenu[0] : null;
    } else {
      this.selectedMenuItem = null;
      this.selectedSubMenuItem = null;
    }
  }

  private mapTocToSubMenu(tocItems: Toc[]): any {
    const itemMap = new Map<number, any>(); 
    tocItems.forEach(item => {
      const menuItem: any = {
        title: item.name,
        contentId: item.id,
        subMenu: [],
        expanded: false
      };
      itemMap.set(item.orderId, menuItem);
    });
    
    tocItems.forEach(item => {
      if (item.parentId !== 0) {
        const parentItem = itemMap.get(item.parentId);
        if (parentItem) {
          parentItem.subMenu.push(itemMap.get(item.orderId));
        }
      }
    });   
    return tocItems.filter(item => item.parentId === 0).map(item => itemMap.get(item.orderId)!);
  }
}