import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppSettings } from "./core/settings.type";

@Injectable({
    providedIn: 'root'
  })

export class AppConfigService {
    public config: AppSettings;
    loaded = false;
    constructor(private http: HttpClient) {}
    loadConfig(): Promise<void> {
        return this.http
            .get<AppSettings>('/appsettings.json')
            .toPromise()
            .then((data:any) => {
                this.config = data;
                this.loaded = true;
            });
    }
    
    getConfig(): AppSettings {
        return this.config;
    }
}