import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'custom-icon-action-cell',
    template: `<button
    class="grid-icon-button"
    (click)="invokeParentMethod()"
    disableRipple="true"
    [disabled]="params.disabled"
    mat-icon-button *ngIf="params">
    <i [ngClass]="params.iconClass" [ngStyle]="{'color': style1 ? 'green' : (style2 ? 'red' : (style1Disabled ? '#50C878' : null)) }"></i>
  </button>`,
})

export class CustomIconActionRendererComponent implements ICellRendererAngularComp {
    public params: any;
    style1: any;
    style2: any;
    style1Disabled: any;

    agInit(params: any): void {
        this.params = params;
        if (typeof this.params.disabled === "function") {
            this.params.disabled = this.params.disabled();
        }

        if (typeof this.params.style === "function") {
            this.params.style = this.params.style();
        }

        if (this.params.style === "style1") {
            this.style1 = true;
            this.style2 = false;
            this.style1Disabled = false;
        } else if (this.params.style === "style2") {
            this.style1 = false;
            this.style2 = true;
            this.style1Disabled = false;
        } else if (this.params.style === "style1Disabled") {
            this.style1 = false;
            this.style2 = false;
            this.style1Disabled = true;
        } else {
            this.style1 = false;
            this.style2 = false;
            this.style1Disabled = false;
        }
    }

    public invokeParentMethod() {
        if (this.params.actionName) {
            if (this.params.actionName === "manageDashboardCellEdit") {
                this.params.context.componentParent[this.params.actionName](this.params);
            }
            else {
                this.params.context.componentParent[this.params.actionName](this.params.node.data);
            }

        } else {
            this.params.context.componentParent.customAction(this.params.node.data);
        }
    }

    refresh(): boolean {
        return false;
    }
}
