import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../../services/mydiet-auth.services';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

export interface DeactivationGuarded {
  canDeactivate(nextState?: string): Observable<boolean> | Promise<boolean> | boolean;
}


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<any>, CanLoad {
  constructor(private authService: AuthService, private router: Router, private cookieService: CookieService) { }


  canActivate(): boolean {
    if (this.router && this.router.getCurrentNavigation()) {
      const existingToken = this.cookieService.get('host-token');
      let queryParams = this.router.getCurrentNavigation();
      //(queryParams?.extractedUrl.fragment && NO_AUTH_ROUTES.includes(queryParams?.extractedUrl.fragment.toUpperCase())) || 
      if (existingToken || queryParams?.extractedUrl.queryParams['token']) { return true; }
      else { return this.checkAuth(); }
    }

    return this.checkAuth();
  }

  canActivateChild(): boolean {
    return this.checkAuth();
  }

  canDeactivate(component: DeactivationGuarded, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return component.canDeactivate ? component.canDeactivate() : true;
  }

  canLoad(): boolean {
    return this.checkAuth();
  }

  private checkAuth(): boolean {
    if (this.authService.isAuthenticatedUser()) {
      return true;
    } else {
      //   // Redirect to the login page if the user is not authenticated
      //   this.router.navigate(['/login']);
      this.router.navigate(['/selfService']);
      return false;
    }
  }

}