import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignupComponent } from './signup.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BlockUIModule } from 'ng-block-ui';
@NgModule({
    declarations: [
        SignupComponent
    ],
    exports : [
        SignupComponent
    ],    
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        BlockUIModule
    ]
})
export class SignupModule { }
