import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-custom-selection-checkbox',
  templateUrl: './custom-selection-checkbox.component.html',
  styleUrls: ['./custom-selection-checkbox.component.scss']
})
export class CustomSelectionCheckboxComponent implements ICellRendererAngularComp {
  public params: any;
  public isChecked: boolean = false;
  public selectedRow: any = null;

  agInit(params: any): void {
    this.params = params;
    this.params.data.isChecked = false;
  }

  refresh(params: any): boolean {
    this.params.context.componentParent.customRefresh(this.params);
    return false;
  }
  update(event: any) {
    this.params = event.node
  }
}