import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { GridApi, GridOptions } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MyDietCommonService } from 'src/app/common/services/mydiet-common.service';
import { MyDietRbacCustomService } from 'src/app/common/services/mydiet-rbac.service';
import { ManualsService } from '../../manuals.service';
import { addendumListAPIResponse, addendumListModel } from 'src/app/common/models/addendum.model';
import { CustomIconActionRendererComponent } from 'src/app/grid-renderers/custom-icon-action-renderer.component';
import { CustomPaginationComponent } from 'src/app/grid-renderers/custom-pagination.component';
import { AddDocumentModalComponent } from '../add-document-modal/add-document-modal.component';
import * as FileSaver from 'file-saver';
import { CommonModalComponent } from 'src/app/common/components/common-modal/common-modal.component';


@Component({
  selector: 'app-addendums',
  templateUrl: './addendums.component.html',
  styleUrls: ['./addendums.component.scss']
})
export class AddendumsComponent {
  @Input() requiredFileType: string;

  gridOptions: GridOptions = {};

  fileName: string;
  facilitySelectedAddendum: Subscription;
  selectedFacility: number | string;
  selectedFacilityId?: number;
  public allowedEdit: boolean = true;
  public docs: addendumListModel[] = [];
  public currentUserRole: string;
  public isUser: boolean = true;
  public isValidRoleAndSubscribed: boolean = true;
  private customPageSize: any = 5;
  private gridApi: GridApi;
  private gridFilterModel: any = [];
  private getAllDocumentsSubscription: Subscription;

  @BlockUI('addendum-data') blockUI: NgBlockUI;

  constructor(private manualService: ManualsService,
    private toastrService: ToastrService,
    private commonService: MyDietCommonService,
    private customRbacService: MyDietRbacCustomService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    let rbacObj = this.customRbacService.rbacCustomObj;
    if (rbacObj?.current_role && rbacObj.current_role.role_name) {
      this.currentUserRole = rbacObj.current_role.role_name;
    }

    if (this.currentUserRole === "User") {
      this.isUser = true;
    } else {
      this.isUser = false;
    }

    this.setDcoumentPreRequiste();
    this.facilitySelectedAddendum = this.commonService.facilityChange
      .subscribe(facility => {
        if (facility && facility.costCenterId) {
          this.selectedFacility = facility.costCenterId;

          if (facility.subscription && facility.subscription.subscriptionName && !this.isUser) {
            this.isValidRoleAndSubscribed = true;
          }
          else {
            this.isValidRoleAndSubscribed = false;
          }


          this.selectedFacilityId = facility.id;

          this.assignGridData();
        }
      }
      );

    this.gridOptions.columnDefs = this.getcolDefs();
    this.gridOptions.frameworkComponents = {
      CustomActionRenderer: CustomIconActionRendererComponent,
      customPaginationComponent: CustomPaginationComponent,
    }
    this.gridOptions.context = { componentParent: this };
    this.gridOptions.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        defaultOption: 'contains'
      }
    };
    this.gridOptions.floatingFilter = true;
    this.gridOptions.pagination = true;
    this.gridOptions.paginationPageSize = 10;
    this.gridOptions.suppressCellSelection = true;
    this.gridOptions.suppressRowClickSelection = true;
    this.gridOptions.singleClickEdit = false;
    this.gridOptions.suppressPaginationPanel = true;
    this.gridOptions.statusBar = {
      statusPanels: [
        {
          statusPanel: 'customPaginationComponent',
          statusPanelParams: {
            pageSizes: [5, 10, 25, 50],
            pageSize: this.customPageSize,
            actionName: 'updatePageCount'
          }
        }
      ],
    };

    this.gridOptions.onSortChanged = this.onSortChanged.bind(this);
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.assignGridData();
  }

  onSortChanged() {
    this.gridState();
  }

  updatePageCount(pageSize: any) {
    this.customPageSize = pageSize;
    this.gridState();
  }

  uploadDocument() {
    if (this.isValidRoleAndSubscribed) {
      this.showUploadModal('new', 0, '', 1);
    }
    else {
      this.toastrService.error('You are not authorized to add documents.');
    }

  }

  showUploadModal(event: string, id: Number, displayName: string, displayOrder: Number, fileName?: string) {
    let modalRef = this.modalService.open(AddDocumentModalComponent, {
      size: 'lg', backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.selectedFacility = this.selectedFacility;
    modalRef.componentInstance.processMode = event;
    modalRef.componentInstance.selectedFacilityId = this.selectedFacilityId;
    modalRef.componentInstance.modifiedBy = this.customRbacService.getUserName();
    modalRef.componentInstance.existingDisplayName = [];
    modalRef.componentInstance.existingFileName = [];

    if (event === 'edit') {
      if (this.docs && this.docs.length > 0 && this.docs.filter(y => y.id !== id) && this.docs.filter(y => y.id !== id).length > 0) {
        modalRef.componentInstance.existingDisplayName = this.docs.filter(y => y.id !== id).map(x => x.displayName.toUpperCase());
        modalRef.componentInstance.existingFileName = this.docs.filter(y => y.id !== id).map(x => x.fileName.toUpperCase());
      }
      modalRef.componentInstance.displayName = displayName;
      modalRef.componentInstance.displayOrder = displayOrder;
      modalRef.componentInstance.id = id;
      modalRef.componentInstance.fileName = fileName;
    }
    else {
      if (this.docs && this.docs.length > 0) {
        modalRef.componentInstance.existingDisplayName = this.docs.map(x => x.displayName.toString().toUpperCase());
        modalRef.componentInstance.existingFileName = this.docs.map(x => x.fileName.toString().toUpperCase());
      }
    }

    modalRef.result.then(res => {
      if (res) {
        this.assignGridData();
      }
    });
  }

  private setDcoumentPreRequiste() {
    if (this.customRbacService && this.customRbacService.rbacCustomObj &&
      this.customRbacService.rbacCustomObj.current_role?.role_name !== "User"
    ) {
      this.allowedEdit = true;
    }
    else {
      this.allowedEdit = false;
    }
  }

  private assignGridData() {
    if (this.selectedFacility && this.gridApi) {
      this.gridApi.showLoadingOverlay();

      if (this.getAllDocumentsSubscription) {
        this.getAllDocumentsSubscription.unsubscribe();
      }
      this.getAllDocumentsSubscription = this.manualService.getFacilitySpecificDocuments(
        Number(this.selectedFacilityId))
        .subscribe((res: addendumListAPIResponse) => {
          this.gridApi.hideOverlay();
          if (res && res.data) {
            this.docs = res.data;
            this.gridOptions.rowData = res.data;
          } else {
            this.gridOptions.rowData = [];
            this.gridApi.showNoRowsOverlay();
          }
        }, () => {
          this.gridOptions.rowData = [];
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        });
    }
  }

  private getColumnDefs() {
    return [
      { field: 'displayName', headerName: 'Name', width: 140, hide: false },
      { field: 'order', headerName: 'Order', width: 30, hide: false },
      {
        field: '', headerName: 'Edit', width: 30, filter: false, suppressMenu: true, sortable: false, hide: !this.allowedEdit,
        cellRendererSelector: () => {
          return {
            component: 'CustomActionRenderer',
            params: {
              disabled: false,
              iconClass: 'fa fa-pencil-alt',
              actionName: 'editRecord'
            }
          };
        }
      },
      {
        field: '', headerName: 'Download', width: 30, filter: false, suppressMenu: true, sortable: false, hide: false,
        cellRendererSelector: () => {
          return {
            component: 'CustomActionRenderer',
            params: {
              disabled: false,
              iconClass: 'fas fa-arrow-to-bottom',
              actionName: 'downloadRecord'
            }
          };
        }
      },
      {
        field: '', headerName: 'Delete', width: 30, filter: false, suppressMenu: true, sortable: false, hide: !this.allowedEdit,
        cellRendererSelector: () => {
          return {
            component: 'CustomActionRenderer',
            params: {
              disabled: false,
              iconClass: 'fas fa-trash',
              actionName: 'deleteRecord',
            }
          };
        }
      },
    ];
  }
  private gridState() {
    let sortFilter = this.gridApi.getSortModel();
    this.gridFilterModel.sortFilter = sortFilter;
    this.gridFilterModel.pageLimit = this.customPageSize;
  }

  getcolDefs() {
    var columnArrayImportData: any = [];
    var columnDefs: any = this.getColumnDefs();

    columnDefs.forEach(function (colDef: { hide: boolean; }, index: any) {
      if (colDef.hide != true) {
        columnArrayImportData.push(colDef);
      }
    });

    return columnArrayImportData;
  }

  deleteRecord(cell: any) {
    if (this.isValidRoleAndSubscribed) {
      let rejectText;
      rejectText = 'Are you sure you want to delete the document: "' + cell.displayName + '"';

      let deleteModal = this.modalService.open(CommonModalComponent, {
        backdrop: 'static',
        keyboard: false,
        size: 'md'
      });

      deleteModal.componentInstance.title = 'Delete Facility Specific Document';
      deleteModal.componentInstance.bodyText = rejectText;
      deleteModal.componentInstance.cancelText = 'No';
      deleteModal.componentInstance.okText = 'Yes';

      deleteModal.result.then(() => {
        this.blockUI.start();
        this.manualService.deleteFacilitySpecificDocument(cell.id, this.selectedFacility.toString()).subscribe({
          next: (res) => {
            if (res && res.metaData && res.metaData.status === "Success") {
              this.toastrService.success('The Diet Document has been Successfully Deleted');
              this.assignGridData();
            } else {
              this.toastrService.error('Could not Delete the Diet Document.');
            }
            this.blockUI.stop();
          },
          error: (error) => {
            console.error(error);
            this.blockUI.stop();
            this.toastrService.error('Could not Delete the Diet Document.');
          }
        });
      }, () => console.info('delete document modal closed.'));
    }
    else {
      this.toastrService.error('You are not authorized to delete documents.Please renew subscritpion.');
    }

  }


  editRecord(cell: any) {
    if (this.isValidRoleAndSubscribed) {
      this.showUploadModal('edit', cell.id, cell.displayName, cell.order, cell.fileName);
    }
    else {
      this.toastrService.error('You are not authorized to edit documents.Please renew subscritpion.');
    }

  }

  downloadRecord(cell: any) {
    this.blockUI.start();
    this.manualService.downloadFacilitySpecificDocument(cell.id, this.selectedFacility.toString())
      .subscribe({
        next: (res) => {
          if (res.size && res.type) {
            FileSaver.saveAs(res, cell.fileName);
          }
          else {
            this.toastrService.error('Error occured while downloading the document.');
          }
          this.blockUI.stop();
        },
        error: (error) => {
          console.error(error);
          this.blockUI.stop();
          this.toastrService.error('Error occured while downloading the document.');
        }
      })
  }

  ngOnDestroy() {
    if (this.facilitySelectedAddendum) {
      this.facilitySelectedAddendum.unsubscribe();
    }
  }
}
