import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { FacilitySelectorComponent } from './facility-selector.component';

@NgModule({
    declarations: [
        FacilitySelectorComponent,
    ],
    exports: [
        FacilitySelectorComponent
    ],
    imports: [
        CommonModule,
        NgSelectModule,
        FormsModule
    ]
})
export class FacilitySelectorModule { }
