<div #newsEditorDiv>
    <div class="modal-header">
        <h4 class="custom-title">{{pageTitle}}</h4>
        <button mat-icon-button type="button" aria-label="Close" (click)="invokeCancel()"
            style="border: none; background:transparent;margin:auto; right: 20px; position: absolute;">
            <i class="fas fa-times"></i>
        </button>
    </div>
    <div *blockUI="'manage-news'">
        <div class="row common-padding-pct-1">
            <div class="row">
                <div class="custom-input">
                    <h5> News Title:<span>*</span></h5>
                </div>
                <div class="row">
                    <div class="input-group mb-3">
                        <input type="text" maxlength="200" (change)="titleChanged()" class="form-control" [(ngModel)]="newsTitle"
                            placeholder="News Title" aria-describedby="basic-addon2">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="row">
                    <div class="custom-input">
                        <h5> News Content:<span>*</span></h5>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div>
                        <quill-editor [(ngModel)]="quillData" (ngModelChange)="onQuillDataChange($event)" style="height: 35vh !important">
                            <div quill-editor-toolbar>
                                <span class="ql-formats">
                                    <button class="ql-bold" [title]="'Bold'"></button>
                                    <button class="ql-italic" [title]="'Italic'"></button>
                                    <button class="ql-underline" [title]="'Underline'"></button>
                                    <button class="ql-strike" [title]="'strike'"></button>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-blockquote" [title]="'Blockquote'"></button>
                                    <button class="ql-code-block" [title]="'Code-block'"></button>
                                </span>
                                <span class="ql-formats">
                                    <button type="button" class="ql-header" value="1"></button>
                                    <button type="button" class="ql-header" value="2"></button>
                                </span>
                                <span class="ql-formats">
                                    <button type="button" class="ql-list" value="ordered"></button>
                                    <button type="button" class="ql-list" value="bullet"></button>
                                </span>
                                <span class="ql-formats">
                                    <button type="button" class="ql-script" value="sub"></button>
                                    <button type="button" class="ql-script" value="super"></button>
                                </span>
                                <span class="ql-formats">
                                    <button type="button" class="ql-indent" value="-1"></button>
                                    <button type="button" class="ql-indent" value="+1"></button>
                                </span>
                                <span class="ql-formats">
                                    <button type="button" class="ql-direction" value="rtl"></button>
                                </span>
                                <span class="ql-formats">
                                    <select class="ql-size ql-picker">
                                        <option value="small"></option>
                                        <option selected="selected"></option>
                                        <option value="large"></option>
                                        <option value="huge"></option>
                                    </select>
                                </span>
                                <span class="ql-formats">
                                    <select class="ql-header ql-picker">
                                        <option value="1"></option>
                                        <option value="2"></option>
                                        <option value="3"></option>
                                        <option value="4"></option>
                                        <option value="5"></option>
                                        <option value="6"></option>
                                        <option selected="selected"></option>
                                    </select>
                                </span>
                                <span class="ql-formats">
                                    <select class="ql-color ql-picker ql-color-picker">
                                        <option selected="selected"></option>
                                        <option value="#e60000"></option>
                                        <option value="#ff9900"></option>
                                        <option value="#ffff00"></option>
                                        <option value="#008a00"></option>
                                        <option value="#0066cc"></option>
                                        <option value="#9933ff"></option>
                                        <option value="#ffffff"></option>
                                        <option value="#facccc"></option>
                                        <option value="#ffebcc"></option>
                                        <option value="#ffffcc"></option>
                                        <option value="#cce8cc"></option>
                                        <option value="#cce0f5"></option>
                                        <option value="#ebd6ff"></option>
                                        <option value="#bbbbbb"></option>
                                        <option value="#f06666"></option>
                                        <option value="#ffc266"></option>
                                        <option value="#ffff66"></option>
                                        <option value="#66b966"></option>
                                        <option value="#66a3e0"></option>
                                        <option value="#c285ff"></option>
                                        <option value="#888888"></option>
                                        <option value="#a10000"></option>
                                        <option value="#b26b00"></option>
                                        <option value="#b2b200"></option>
                                        <option value="#006100"></option>
                                        <option value="#0047b2"></option>
                                        <option value="#6b24b2"></option>
                                        <option value="#444444"></option>
                                        <option value="#5c0000"></option>
                                        <option value="#663d00"></option>
                                        <option value="#666600"></option>
                                        <option value="#003700"></option>
                                        <option value="#002966"></option>
                                        <option value="#3d1466"></option>
                                    </select>
                                </span>
                                <span class="ql-formats">
                                    <select class="ql-background ql-picker ql-color-picker">
                                        <option value="#000000"></option>
                                        <option value="#e60000"></option>
                                        <option value="#ff9900"></option>
                                        <option value="#ffff00"></option>
                                        <option value="#008a00"></option>
                                        <option value="#0066cc"></option>
                                        <option value="#9933ff"></option>
                                        <option selected="selected"></option>
                                        <option value="#facccc"></option>
                                        <option value="#ffebcc"></option>
                                        <option value="#ffffcc"></option>
                                        <option value="#cce8cc"></option>
                                        <option value="#cce0f5"></option>
                                        <option value="#ebd6ff"></option>
                                        <option value="#bbbbbb"></option>
                                        <option value="#f06666"></option>
                                        <option value="#ffc266"></option>
                                        <option value="#ffff66"></option>
                                        <option value="#66b966"></option>
                                        <option value="#66a3e0"></option>
                                        <option value="#c285ff"></option>
                                        <option value="#888888"></option>
                                        <option value="#a10000"></option>
                                        <option value="#b26b00"></option>
                                        <option value="#b2b200"></option>
                                        <option value="#006100"></option>
                                        <option value="#0047b2"></option>
                                        <option value="#6b24b2"></option>
                                        <option value="#444444"></option>
                                        <option value="#5c0000"></option>
                                        <option value="#663d00"></option>
                                        <option value="#666600"></option>
                                        <option value="#003700"></option>
                                        <option value="#002966"></option>
                                        <option value="#3d1466"></option>
                                    </select>
                                </span>
                                <span class="ql-formats">
                                    <select class="ql-font ql-picker">
                                        <option selected="selected"></option>
                                        <option value="serif"></option>
                                        <option value="monospace"></option>
                                    </select>
                                </span>
                                <span class="ql-formats">
                                    <select class="ql-align ql-picker ql-icon-picker" [title]="'Aligment'">
                                        <option selected></option>
                                        <option value="center"></option>
                                        <option value="right"></option>
                                        <option value="justify"></option>
                                    </select>
                                </span>
                                <span class="ql-formats">
                                    <button type="button" class="ql-clean"></button>
                                </span>
                            </div>
                        </quill-editor>
                    </div>
                </div>
            </div>
        </div>

        <div class="row common-padding-pct-1">
            <div class="row">
                <div class="col-sm-12 col-lg-2 col-md-2" style=" align-self: center;">
                    <div class="custom-input">
                        <h5> Expiry Date:<span>*</span></h5>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-4 col-md-4" style="width: 30%;">
                    <mat-form-field appearance="fill" (click)="ExpiryDatePicker.open()">
                        <input matInput [matDatepicker]="ExpiryDatePicker" [max]="maxExpiryDate" [min]="minExpiryDate"
                            [(ngModel)]="selectedExpiryDate" (click)="ExpiryDatePicker.open()" (ngModelChange)="expiryDateChanged()" required>
                        <mat-datepicker-toggle matSuffix [for]="ExpiryDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #ExpiryDatePicker></mat-datepicker>
                        <mat-error class="custom-style-matError" *ngIf="!selectedExpiryDate">
                            Expiry Date is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-lg-6 col-md-6" style=" align-self: center;">
                    <span *ngIf="!isValidExpiryDate" class="common-error-span"> Given Expiry Date is Invalid.</span>
                </div>
            </div>
        </div>

        <div style="float: right;" class="row">
            <div>
                <button class="btn btn-primary kt-margin-10" (click)="saveData()"
                    [disabled]="!newsTitle || !newsTitle.toString().trim() || !quillData || !quillDataAvailable || !selectedExpiryDate || !validRoleAndSubscribed">Save</button>
                <button [disabled]="!validRoleAndSubscribed" *ngIf="isEdit" class="btn btn-primary kt-margin-10"
                    (click)="invokeDeleteModal()">Delete</button>
                <button type="button" class="btn btn-secondary"
                    (click)="invokeCancel()">Cancel</button>&nbsp;&nbsp;
            </div>
        </div>
    </div>
</div>