import { PathLocationStrategy, PlatformLocation } from '@angular/common';
import { UrlSerializer } from '@angular/router';
import { Optional, Inject, Injectable } from '@angular/core';


@Injectable()
export class ParameterHashLocationStrategy extends PathLocationStrategy {
    private get search(): string {
        return this.platformLocation?.search ?? '';
    }
    constructor(
        private platformLocation: PlatformLocation,
        private urlSerializer: UrlSerializer
    ) {
        super(platformLocation);
    }
    override prepareExternalUrl(internal: string): string {
        const path = super.prepareExternalUrl(internal);
        const existingURLSearchParams = new URLSearchParams(this.search);
        const existingQueryParams = Object.fromEntries(existingURLSearchParams.entries());
        const urlTree = this.urlSerializer.parse(path);
        const nextQueryParams = urlTree.queryParams;
        urlTree.queryParams = { ...existingQueryParams, ...nextQueryParams };
        return urlTree.toString();
    }
}