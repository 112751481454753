<div class="custom-pagination">
    <div *ngIf="pageSizes && pageSizes.length" class="pagination-size kt-margin-r-10">
      <select class="pagination-size-select" [(ngModel)]="pageSize" (change)="pageSizeChanged()">
        <option *ngFor="let size of pageSizes" [value]="size">{{size}}</option>
      </select>&nbsp;&nbsp;items per page
    </div>
  
    <span class="ag-paging-row-summary-panel kt-margin-r-10 kt-margin-l-10">
      <span class="ag-paging-row-summary-panel-number kt-padding-r-5">{{ (pageSize * (currentPage - 1)) + 1 }}</span>to
      <span class="ag-paging-row-summary-panel-number kt-padding-r-5">{{ currentPageMaxCount }}</span>of
      <span class="ag-paging-row-summary-panel-number">{{ totalRowsCount }}</span>
    </span>
  
    <div class="ag-paging-page-summary-panel">
      <div class="ag-paging-button-wrapper"><span class="ag-icon ag-icon-first" ></span>
          <button type="button" class="ag-paging-button" (click)="goToFirstPage()">First</button>
      </div>
      <div class="ag-paging-button-wrapper"><span class="ag-icon ag-icon-previous"></span>
          <button type="button" class="ag-paging-button" (click)="goToPreviousPage()">Previous</button>
      </div>
      <span class="ag-paging-description">
          Page
          <span class="ag-paging-number">{{currentPage}}</span>
          of
          <span class="ag-paging-number">{{totalPages}}</span>
      </span>
      <span class="ag-paging-number"></span>
      <div class="ag-paging-button-wrapper"><span class="ag-icon ag-icon-next"></span>
          <button type="button" class="ag-paging-button" (click)="goToNextPage()">Next</button>
      </div>
      <div class="ag-paging-button-wrapper"><span class="ag-icon ag-icon-last"></span>
          <button type="button" class="ag-paging-button" (click)="goToLastPage()">Last</button>
      </div>
    </div>
  
  </div>
  