import { Component } from '@angular/core';
import { IStatusPanelParams } from 'ag-grid-community'
@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.scss']
})
export class CustomPaginationComponent {
  private params: any;
  pageSize = 25;
  pageSizes: any;
  currentPage: number = 0;
  totalPages: number = 1;
  totalRowsCount: number = 0;
  currentPageMaxCount: number = 0;

  agInit(params: IStatusPanelParams): void {
    this.params = params;
    if (this.params.pageSizes) {
      this.pageSizes = this.params.pageSizes;
    }
    if (this.params.pageSize) {
      this.pageSize = this.params.pageSize;
      this.params.api.paginationSetPageSize(this.pageSize);
    }


      params.context.componentParent.gridOptions.onPaginationChanged = this.paginationChanged.bind(this);


  }

  paginationChanged() {
    this.currentPage = this.params.api.paginationGetCurrentPage() + 1;
    this.totalPages = this.params.api.paginationGetTotalPages() ? this.params.api.paginationGetTotalPages() : 1;
    this.totalRowsCount = this.params.api.paginationGetRowCount();
    this.currentPageMaxCount = this.currentPage === this.totalPages ? this.params.api.paginationGetRowCount() : this.pageSize * this.currentPage;
  }

  goToFirstPage() {
    this.params.api.paginationGoToFirstPage();
  }

  goToNextPage() {
    this.params.api.paginationGoToNextPage();
  }

  goToPreviousPage() {
    this.params.api.paginationGoToPreviousPage();
  }

  goToLastPage() {
    this.params.api.paginationGoToLastPage();
  }

  pageSizeChanged() {
    this.params.api.paginationSetPageSize(this.pageSize);
    if (this.params.actionName) {
      this.params.context.componentParent[this.params.actionName](this.pageSize);
    }
  }

}
