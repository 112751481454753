import { Injectable } from '@angular/core';
import { MyDietCommonService } from './mydiet-common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private isAuthenticated = false;
  private authSecretKey = 'Bearer Token';

  constructor(private commonService : MyDietCommonService) { 
   // this.isAuthenticated = this.commonService.oAuthToken();
  }

  isAuthenticatedUser(): boolean {
    if(this.commonService.oAuthToken)
        {
            return true;
        }
        else
        {
            return false;
        }
   // return this.isAuthenticated;
  }

}