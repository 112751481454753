import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignupComponent } from '../signup/signup.component';
import { PreLoginComponent } from './pre-login.component';

const routes: Routes = [

  { path: '', component: PreLoginComponent },
  {
    path: 'selfservice',
    children: [
      {
        path: '',
        component: PreLoginComponent
      },
      {
        path: 'prelogin',
        component: PreLoginComponent
      }
    ]
  },
  { path: 'signup', component: SignupComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PreLoginRoutingModule { }
