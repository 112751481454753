<div class="modal-header">
    <h4 class="custom-title">{{title}}</h4>
    <button mat-icon-button type="button" aria-label="Close" (click)="activeModal.dismiss(null)"
        style="border: none; background:transparent;margin:auto; right: 20px; position: absolute;">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <div class="headerContent">
        <div class="row" *ngIf="bodyTitle">
            <div class="col-sm-12">
                {{ bodyTitle }}
            </div>
        </div>
    </div>
    
    <div class="row" class="news-editor-display" *ngIf="bodyText">
        <div class="col-sm-12" [innerHTML] = "bodyText">
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary"
        (click)="activeModal.dismiss('cancel')">{{cancelText}}</button>&nbsp;&nbsp;
</div>