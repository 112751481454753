import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { PageHeaderModule } from '@stg-js-ngx-core/page-header';
import { OauthSessionModule } from '@stg-js-ngx-core/oauth';
import { FooterModule } from '@stg-js-ngx-core/footer';
import { DesktopModule } from '@stg-js-ngx-core/desktop';
import { LeftNavModule } from '@stg-js-ngx-core/left-nav';
import { HeaderModule } from '@stg-js-ngx-core/header';
import { BlockUIModule } from 'ng-block-ui';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreLoginModule } from './common/components/pre-login/pre-login.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignupModule } from './common/components/signup/signup.module';
import { CommonModalComponent } from './common/components/common-modal/common-modal.component';
import { CustomCheckboxComponent } from './common/components/custom-checkbox/custom-checkbox.component';
import { CustomEmailComponent } from './common/components/custom-email/custom-email.component';
import { CustomDropdownComponent } from './common/components/custom-dropdown/custom-dropdown.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LocationStrategy } from '@angular/common';
import { ParameterHashLocationStrategy } from './ParameterHashLocationStrategy';
import { CustomValidationComponent } from './common/components/custom-validation/custom-validation.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';

import { AppConfigService } from './appServiceConfig';
import { NewsModule } from './news/news.module';
import { ManualsModule } from './manuals/manuals.module';
import { FacilitySelectorModule } from './common/components/facility-selector/facility-selector.module';
import { CustomImageIconComponent } from './common/components/custom-image-icon/custom-image-icon.component';
import { CustomSelectionCheckboxComponent } from './common/components/custom-selection-checkbox/custom-selection-checkbox.component';



export function initConfig(appConfig: AppConfigService) {
    return () => appConfig.loadConfig();
}

@NgModule({
    declarations: [
        AppComponent,
        CommonModalComponent,
        CustomCheckboxComponent,
        CustomEmailComponent,
        CustomDropdownComponent,
        CustomValidationComponent,
        CustomImageIconComponent,
        CustomSelectionCheckboxComponent
    ],
    imports: [
        BrowserModule,
        PageHeaderModule,
        AppRoutingModule,
        OauthSessionModule.forRoot(),
        FooterModule,
        DesktopModule,
        LeftNavModule,
        HeaderModule,
        BlockUIModule,
        PreLoginModule,
        ReactiveFormsModule,
        FormsModule,
        SignupModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatBadgeModule,
        NewsModule,
        ManualsModule,
        FacilitySelectorModule,
    ],
    providers: [
        CookieService,
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [AppConfigService],
            multi: true,
        }, { provide: LocationStrategy, useClass: ParameterHashLocationStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
