import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MyDietCommonService } from 'src/app/common/services/mydiet-common.service';
import { AppConfigService } from 'src/app/appServiceConfig';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})


export class NewUserApprovalsService {

  _oAuthToken: any = "";


  constructor(private http: HttpClient,private appConfig : AppConfigService,private commonService : MyDietCommonService) { 
    this._oAuthToken = this.commonService.oAuthToken;
  }

  get oAuthToken(): any {
    return this._oAuthToken;
  }

  set oAuthToken(value: any) {
    if (value) {
      this._oAuthToken = value;
    } else {
      this._oAuthToken = "";
    }
  }

  getHeaders(): HttpHeaders {
    const authToken = this.oAuthToken;  
    let headers = new HttpHeaders().set('Content-Type', 'application/json',).set('Authorization', `Bearer ${authToken}`).set('Access-Control-Allow-Origin', '**');
    return headers;  
  }

  validateCostCenter(body: any): Observable<any>  {
    const headers = this.getHeaders();
    return this.http.post(this.appConfig.getConfig().api.uri + environment.urls.validateCostCenter, body, { headers });
  }
  
}