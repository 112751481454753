<div class="row" *blockUI="'hospital-news'; message: 'Loading';">
  
        <div class="col-sm-12">
            <div class="row">
                <div class="col-sm-12 col-lg-4 col-md-4">
                    <h4 style="margin-top: revert !important;" class="kt-font-prop">Facility News </h4>
                </div>
                <div class="col-sm-4 col-lg-4 col-md-4">
                    <ng-select name="sortHospNews" [searchable]="false" [clearable]="false" [(ngModel)]="sortNewsModel"
                        (change)="sortNewsChange()" [items]="sortNewsOptions" required>
                    </ng-select>
                </div>
                <div style="position: relative; margin-left: auto;" class="col-sm-4 col-lg-4 col-md-4">
                    <button type="button" (click)="addFacilityNews()" [disabled]="!isValidRoleAndSubscribed" class="btn btn-outline-primary btn-sm news-btn">
                        <i class="fas fa-plus"></i>
                        Add News</button>
                </div>
            </div>
    
            <div class="row">
                <app-news-display [isValid]="isValidRoleAndSubscribed" [pageSource] ="pageSource" (editInitiatedParent) = "initiateEdit($event)" [newsData]="hospNews"></app-news-display>
            </div>
            <div class="row" class="common-no-news-txt" *ngIf="!isHospNewsAvailableForFacility">
                <h5 class="subHeader">Facility News Not Available For Selected Facility.</h5>
            </div>
        </div>

</div>