import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { newsModel } from 'src/app/common/models/news.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewsDisplayModalComponent } from '../news-display-modal/news-display-modal.component';

@Component({
  selector: 'app-news-display',
  templateUrl: './news-display.component.html',
  styleUrls: ['./news-display.component.scss']
})
export class NewsDisplayComponent implements OnInit {

  constructor(private modalService: NgbModal) { }
  @Input() newsData: newsModel[];
  @Input() isValid: boolean = true;
  @Input() pageSource: string = 'System Administrator';
  @Output() editInitiatedParent = new EventEmitter<any>();

  ngOnInit(): void {
  }

  openNewsModal(news: any) {
    let newsEntryModal = this.modalService.open(NewsDisplayModalComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'md'
    });

    newsEntryModal.componentInstance.title = this.pageSource === 'System Administrator'?'System News Entry':'Facility News Entry';
    newsEntryModal.componentInstance.bodyTitle = news.title;
    newsEntryModal.componentInstance.bodyText = news.content;
  }

  editInitiated(news: any) {
    this.editInitiatedParent.emit(news);
  }
}
