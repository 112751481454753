import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPaginationComponent } from './custom-pagination.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomIconActionRendererComponent } from './custom-icon-action-renderer.component';

@NgModule({
    declarations: [
    CustomPaginationComponent,
    CustomIconActionRendererComponent  
    ],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule
    ]
  })
  export class GridRenderersModule { }
  